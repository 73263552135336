<template>
    <div>
        <div class="">
            <div style="background-color:#BFD243;height:auto;">
                <ul class="accordion-menu mobile" v-show="$store.getters.getOpenMobileMenuAccount" id="menu_mobil_ul">
                  <!-- <li id="menu_principal_administrator">
                        <img loading="lazy"  src="@/assets/Admin/icon-home.svg" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;">
                        <router-link :to="`/${account}/principal`" class="menu__item" >
                            <div class="dropdownlink">Pantalla Principal
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </router-link>
                    </li> -->

                    <li v-if="accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">
                        <img  src="@/assets/Admin/icon-home.svg" width="20" height="20" style="float:left; margin-top:15px; margin-left:10px;" >
                        <router-link :to="`/${account}/principal`" class="menu__item">
                            <div class="dropdownlink">Inicio
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </router-link>
                    </li>

                    <li  v-if="accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">
                      <img  src="@/assets/Cuenta/datos.svg" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;" >
                      <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Inscripción Asistida
                          <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                      <ul class="submenuItems">
                        <li>
                          <router-link :to="`/${account}/captura-asistida/seccion/uno`" >FONCABSA</router-link>
                        </li>
                        <li>
                          <router-link :to="`/${account}/captura-asistida/sinaptissste/seccion/uno`">SINAPTISSSTE</router-link>
                        </li>
                      </ul>
                    </li>
  
                    <li id="menu_reports" v-if="accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">
                        <img loading="lazy"  src="@/assets/Admin/icon-document-add.svg" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;" >
                        <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Reportes
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <ul class="submenuItems">
                            <li id="menu_loading_itbks">
                              <router-link :to="`/${account}/reportes/carga-itbanks`" >Carga ITBNKS</router-link>
                            </li>
                            <li id="il_generate_new_layout">
                              <router-link :to="`/${account}/reportes/layouts/socios`" >Layout Altas ITBNKS</router-link>
                            </li>
                            <li id="il_configuration_folio">
                              <router-link :to="`/${account}/itbanks/configuracion/folio`" >Configuración de Folios Clientes ITBNKS</router-link>
                            </li>
                        </ul>
                    </li>

                    <li id="menu_products" v-if="accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor' || accountType=='enroller' || accountType == 'financial_advisor' ">
                        <img  src="@/assets/Cuenta/datos.svg" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;" >
                        <div @click="open_hamburger('products')" class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Productos
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <ul class="submenuItems">
                          <li v-if="false">
                            <router-link :to="`/${account}/productos/vida-simple`">Vida Simple</router-link>
                          </li>
                          <li>
                            <router-link :to="`/${account}/productos/vida-total`">Vida Total</router-link>
                          </li>
                        </ul>
                    </li>
                    
                    <li id="menu_payments" v-if="accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">
                      <img  src="@/assets/Admin/icon-document-add.svg" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;" >
                      <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Pagos
                          <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                      <ul class="submenuItems">
                          <li id="menu_loading_itbks"><router-link :to="`/${account}/pagos/inscripcion`" >Pagos de inscripción</router-link></li>
                          <li id="menu_loading_itbks"><router-link :to="`/${account}/pagos/aportacion`" >Pagos de aportación</router-link></li>
                      </ul>
                    </li>
  
                    <li v-if="accountType == 'member' || accountType == 'cabsa_member'" id="menu_principal">
                        <img loading="lazy"  src="@/assets/Admin/icon-home.svg" width="20" height="20" style="float:left; margin-top:15px; margin-left:10px;" >
                        <router-link to="/cuenta/principal" class="menu__item">
                            <div class="dropdownlink">Principal
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </router-link>
                    </li>

                    <li id="menu_assisted_capture">
                        <img loading="lazy"  src="https://icongr.am/entypo/key.svg?size=82&color=ffffff" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;">
                        <router-link :to="`/${account}/cambiar-contrasena`" class="menu__item" >
                            <div class="dropdownlink">Cambiar Contraseña
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </router-link>
                    </li>
                    
                    <li v-if="accountType == 'administrator'" id="menu_commission">
                        <img loading="lazy"  src="@/assets/Admin/icon-document-add.svg" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;" >
                        <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Comisiones
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <ul class="submenuItems">
                            <li id="menu_commission_scheme"><router-link :to="`/${account}/commissions/newcommissionscheme`">Nuevo esquema de comisiones</router-link></li>
                            <li id="menu_capture_individual_product_bonus_scheme"><router-link :to="`/${account}/commissions/capturebonusscheme`">Capturar Esquema de Bono Individual por Producto</router-link></li>
                            <li id="menu_assign_scheme_commissions"><router-link :to="`/${account}/commissions/assignschemecommissions`">Asignar Esquema de Comisiones Individual</router-link></li>
                            <li id="ul_menu_capture_period"><router-link to="/commissions/SetUpProductCommissionPayments">Configurar pagos de comisiones de productos</router-link></li>
                            <li id="ul_menu_capture_period"><router-link to="/commissions/recordperiodpaymentcommissions">Captura un período para pago de comisiones</router-link></li>       
                        </ul>
                    </li>

                    <li id="menu_assisted_capture" v-if="accountType=='member_analyst' || accountType=='supervisor' || accountType=='loan_analyst'">
                        <img loading="lazy"  src="@/assets/Admin/icon-document-add.svg" width="20" height="20" style="float:left; margin-top:10px; margin-left:10px;" >

                        <router-link :to="`/${account}/referralhistory`" class="menu__item" >
                            <div class="dropdownlink">Historial de referidos
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </router-link>
                    </li>

                    <!-- cuneta de cliente inicio -->

                    <li id="menu_complit_register" v-if="accountType == 'member' || accountType == 'cabsa_member'">
                      <img loading="lazy" src="@/assets/Admin/icon-document-add.svg" width="20" height="20" style="float: left; margin-top: 15px; margin-left: 15px" />
                        <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Mis Datos
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <ul class="submenuItems">
                          <li>
                            <router-link to="/cuenta/completar-registro">Completa tu registro</router-link>
                          </li>
                          <li>
                            <router-link to="/cuenta/carga-documentos/1">Carga de documentos</router-link>
                          </li>
                          <li v-if="admission_status == 'completada'" >
                            <router-link to="/cuenta/beneficiaries">Beneficiarios</router-link>
                          </li>
                        </ul>
                    </li>

                    <li v-show="(accountType == 'member' || accountType == 'cabsa_member') && missingData.length > 0 && organizations.includes('sinaptissste')"
                      id="menu_change_personal_data_movile">
                      <img
                        src="@/assets/Cuenta/datos.svg"
                        width="20"
                        height="20"
                        style="float: left; margin-top: 15px; margin-left: 15px"
                      />
                      
                      <div class="dropdownlink">
                        Actualiza tus datos  <span  class="inline-block w-6 h-6 rounded-full bg-green-400 text-white text-center">{{ missingData.length }}</span>
                        <br />
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                      <ul class="submenuItems">
                        <li v-for="(module,index) in missingData" :key="module != null? module.module_name: ''" :id="module != null? module.module_name.toString().toLowerCase().replaceAll(' ','_'): ''">
                          <router-link v-if="module != null" class="normal-case" :to="module.route">{{ module != null? module.module_name:'' }}</router-link>
                        </li>
                      </ul>
                    </li>

                    <li v-if="accountType == 'member' || accountType == 'cabsa_member'" id="menu_principal">
                        <img loading="lazy"  src="@/assets/Cuenta/contrasena.svg" width="20" height="20" style="float: left; margin-top: 18px; margin-left: 15px" />
                        <router-link to="/cuenta/saving-minors" class="menu__item">
                            <div class="dropdownlink">Ahorro para menores de edad
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </router-link>
                    </li>

                    <li id="menu_referidos" v-if="accountType == 'member' || accountType == 'cabsa_member'">
                      <img loading="lazy" src="@/assets/Cuenta/person-plus-fill.svg" width="25" height="25" style="float: left; margin-top: 15px; margin-left: 15px" />
                        <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Referidos
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <ul class="submenuItems">
                          <li>
                            <router-link to="/cuenta/referidos/referir-persona">Referir Persona</router-link>
                          </li>
                          <li>
                            <router-link to="/cuenta/referidos">Historial de Referidos</router-link>
                          </li>
                        </ul>
                    </li>

                    <li id="menu_referidos" v-if="clientId != 0 && (accountType == 'member' || accountType == 'cabsa_member') && !organizations.includes('sinaptissste')">
                      <img loading="lazy" src="@/assets/Cuenta/datos.svg" width="20" height="20" style="float: left; margin-top: 15px; margin-left: 15px" />
                      <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Actualiza tus datos
                          <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>

                      <ul class="submenuItems">
                        <li>
                          <router-link to="/cuenta/update-personal-data/rfc">RFC</router-link>
                        </li>
                        <li>
                          <router-link to="/cuenta/update-personal-data/update-ine">Actualizar Identificación</router-link>
                        </li>
                        <li>
                          <router-link to="/cuenta/update-personal-data/adress">Dirección</router-link>
                        </li>
                        <li>
                          <router-link to="/cuenta/update-personal-data/marital-status">Estado Civil</router-link>
                        </li>
                        <li>
                          <router-link to="/cuenta/update-personal-data/work-informarion">Laboral</router-link>
                        </li>
                        <li>
                          <router-link to="/cuenta/update-personal-data/bank-information">Información Bancaria</router-link>
                        </li>
                        <li>
                          <router-link to="/cuenta/update-personal-data/contact">Contacto</router-link>
                        </li>
                        
                      </ul>

                    </li>

                    <li id="menu_referidos" v-if="clientId != 0 && (accountType == 'member' || accountType == 'cabsa_member') && !organizations.includes('sinaptissste')">

                      <img loading="lazy" src="@/assets/Cuenta/datos.svg" width="20" height="20" style="float: left; margin-top: 15px; margin-left: 15px" />
                      <div class="dropdownlink">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        <router-link to="/cuenta/beneficiaries"
                          >Actualizar beneficiarios</router-link
                        >
                      </div>

                    </li>


                    <!-- cuneta de cliente fin -->

                    <li id="menu_cloused_session">
                        <img loading="lazy"  src="@/assets/Admin/cerrar-sesion.svg" width="20" height="20" style="float:left; margin-top:15px; margin-left:10px;">
                        <a  v-on:click="Logout" class="menu__item" >
                            <div class="dropdownlink">Cerrar Sesión
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  import {getUserInfo, getTokenDecoden} from "@/helpers/tokenauth";
  import { getAdmissionRequestOrCreate, createMissingDataArray, getMissingData } from "@/api/user";
  import { computed, onBeforeMount, ref } from "vue";
  import { useStore } from "vuex";

  export default {
  name: 'MenuLateralMobile',
  data() {
    return {
      open: false,
      accountType: "",
      account: "",
      organizations: []
    }
  },
  props: {
  },
  created() {
    this.loadInfo();
  },
  mounted() {
    $(document).ready(function() {
      $('.menu__icon').click(function(){
        $('body').toggleClass('menu_shown');
      });
    });
  },
  methods:{
    Logout(){
      sessionStorage.clear();
      this.$router.push({path:"/"});
    },
    openMenu() {
      this.open = !this.open;
    },
    loadInfo() {
      const dataAccount = getUserInfo();
      this.account = dataAccount.account;

      this.get_data_client();
    },
    async preLogout(){
      await this.getAdmissionRequest();
      
      if(["aceptada","cancelada","rechazada","en_revision","pendiente_de_revision","expirada"].includes(this.admission_status)){
        this.Logout();
      }else{
        var today1 = new Date()
        today1.setDate(today1.getDate());

        var dateExpiration = new Date(this.admission_registration_date);
        dateExpiration.setDate(dateExpiration.getDate() + 14);

        var today2 = new Date()
        today2.setDate(today2.getDate() + this.datediff(today1, dateExpiration));

        var text = "<p style='font-size:14px'>Le recordamos que tiene hasta el día "+ this.formatDate(today2) +" para concluir su registro.<br><br>FONCABSA agradece su preferencia..</p>";
        Swal.fire({
          html: text,
          confirmButtonColor: '#FEB72B',
        }).then((result) => {
          this.Logout();
        })
      }
    },
    async Logout(){
      sessionStorage.clear();
      window.location.href = "/"
      //this.router.push({path:"/"});
    },
    openMenu() {
      this.open = !this.open;
    },
    datediff(first, second) {
      return Math.round((second-first)/(1000*60*60*24));
    },
    async getAdmissionRequest() {
      let token = sessionStorage.getItem("login");
      await getAdmissionRequestOrCreate(token,this.personId).then((response) => {
        this.admission_registration_date = response.data.response.admission_request.created_at
        this.admission_status = response.data.response.admission_request.status
      })
      .catch((error) => {
      });
    },
    formatDate(date) {
      var d = new Date(date.toString());
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    get_data_client()
      {
          const auth=getTokenDecoden();
          this.accountType = auth.obj['role'];
          this.organizations = auth.obj.organization_list;
          
          if(this.accountType == "member" || this.accountType == "cabsa_member")
          {
            const auth = getTokenDecoden();
            this.personId = auth.obj.person["id"];

            this.getAdmissionRequest();
          }
        },
    },
  setup() {
    const store = useStore();
    const clientId = ref(0)
    const missingData = computed(()=> {
      return store.getters.getCountMissingDataNumber;
    });

    onBeforeMount(async()=> {
      clientId.value = getUserInfo().client_id;

      let missingDataList = getMissingDataFromStore();

      if(missingDataList.length == 0 && clientId.value > 0){
        await getMisingDataArray();
      }
    });

    function getMissingDataFromStore(){
      return store.getters.getCountMissingDataNumber;
    }

    async function getMisingDataArray(){
      let missingData = await getMissingClientData();

      if(missingData != null){
        let adsMissingData = await createMissingDataArray(missingData);
        store.dispatch("setCountMissingDataAction", {value: adsMissingData});
      }
    }
    
    async function getMissingClientData(){
      return await getMissingData(sessionStorage.getItem("login"), clientId.value, "new_client").then(resp => (!resp.data.response.has_error)? resp.data.response.result: null).catch(err => null);
    }
   

    return { missingData, clientId };
  }
  }
  
  
  </script>
  
  <style scoped>
  * {
  margin: 0;
  padding: 0;
  }
  
  body {
  background: #388E3C;
  }
  
  ul {
  list-style: none;
  }
  
  a {
  text-decoration: none;
  }
  
  h2 {
  text-align: center;
  margin: 20px auto;
  color: #fff;
  }
  
  .accordion-menu {
  width: 100%;
  max-width: 80%;
  
  background: #810042;
  border-radius: 4px;
  }
  
  .accordion-menu li a{
    text-decoration: none;
  }
  .accordion-menu li.open .dropdownlink {
  color: #726659;
  }
  
  .accordion-menu li.open .dropdownlink .fa-chevron-down {
    transform: rotate(180deg);
  }
  
  .accordion-menu li:last-child .dropdownlink {
  border-bottom: 0;
  }
  .dropdownlink {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 45px;
  font-family: Roboto;
  font-weight: 300;
  font-size: 15px;
  border-bottom: 1px solid #FFF;
  color: #FFF;
  position: relative;
  transition: all 0.4s ease-out;
  }
  
  .dropdownlink i {
    position: absolute;
    top: 17px;
    left: 16px;
    margin-top:10px;
  }
  
  .dropdownlink .fa-chevron-down {
    right: 12px;
    left: auto;
  }
  
  .submenuItems {
  display: none;
  background: #FFF;
  }
  
  .submenuItems li {
     border-bottom: 1px solid #FFF;
  }
  
  .submenuItems li a {
  text-decoration: none;
  }
  
  .submenuItems a {
  display: block;
  color: #690B2F;
  padding: 12px 12px 12px 45px;
  transition: all 0.4s ease-out;
  }
  
  /* .submenuItems a:hover {
    background-color: #FFF;
    color: #fff;
  } */
  
  .container_menu_icon{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 12px;
  right: 3%;
  display: inline-block;
  vertical-align: middle;
  z-index: 20;
  background: rgba(255, 255, 255, 0.4);
  }
  
  .menu__icon {
  height: 22px;
  width: 33px;
  margin: 3%;
  position: absolute;
  top: 12px;
  right: 15%;
  display: inline-block;
  vertical-align: middle;
  z-index: 20;
  }
  
  .menu__icon span {
  display: block;
  background: #fff;
  width: 100%;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  right: 0;
  top: 50%;
  }
  
  .menu__icon:before,
  .menu__icon:after {
  content: "";
  display: block;
  background: #fff;
  font-weight: bold;
  width: 100%;
  height: 4px;
  position: absolute;
  right: 0;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: all 0.3s ease;
  opacity: 1 !important;
  }
  
  .menu__icon:before {
  top: 2px;
  margin-top: -2px;
  }
  
  .menu__icon:after {
  bottom: 2px;
  margin-bottom: -2px;
  }
  
  .menu_shown .menu__icon span {
  background: transparent;
  }
  
  .menu_shown .menu__icon:before {
  top: 50%;
  transform: rotate(45deg);
  }
  
  .menu_shown .menu__icon:after {
  bottom: 50%;
  transform: rotate(-45deg);
  }
  
  .mobile.accordion-menu {
  position: absolute;
  left: 0;
  width: 100%;
  min-height: auto;
  padding-top: 0px;
  z-index: 10;
  /* transition: all 0.4s ease-in-out;
  transform: translateX(100%); */
  }
  
  @media (max-width: 767px) {
  .container_menu_icon {
    display: block;
  } 
  }
  
  @media (min-width: 767px) {
  nav {
    padding: 3% 6%;
  }
  
  .mobile {
    display: none;
  }
  
  .desktop {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  }
  
  </style>